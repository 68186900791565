import { useQuery } from "@tanstack/react-query";
import ModalDialog from "../../../layout/modal-dialog";
import SelectNeoGen from "../../../layout/select-neogen";
import processflowGroupService from "../../../services/processflow-group.service";
import { useEffect, useState } from "react";
import processflowService from "../../../services/processflow.service";
import PrintPre from "../../../layout/print-pre";

export default function ResponseVariableModal({
    show,
    hide,
    setValue,
}: {
    show: boolean;
    hide: () => void;
    setValue: (value: string) => void;
}) {
    const [processflowGroup, setProcessflowGroup] = useState(7); // TODO: [2021-10-06 Wed 14:06
    const [entries, setEntries] = useState<any[]>([]);
    const [entry, setEntry] = useState("");
    const processflowGroupQuery = useQuery(["ProcessflowGroup"], async () => {
        const response = await processflowGroupService.getAll();
        if (response) {
            return response.data;
        }
    });
    const [processflow, setProcessflow] = useState(7);
    const processflowQuery = useQuery(["Processflow", processflowGroup], async () => {
        const response = await processflowService.getAllByGroupId(processflowGroup);
        if (response) {
            return response.data;
        }
    });
    useEffect(() => {
        if (processflow) {
            const entry = processflowQuery?.data?.find((p) => p.id === processflow)?.entries;
            if (entry) {
                const entryTemp = JSON.parse(entry);

                setEntries(
                    entryTemp.filter((e: any) => ["checkbox", "state", "textarea", "textfield"].includes(e.type)),
                );
            }
        }
    }, [processflow, processflowQuery?.data]);
    return (
        <ModalDialog
            size="xl"
            close={hide}
            show={show}
            title={""}
            okAction={() => {
                hide();
                setValue(processflowGroup + ":" + entry);
            }}
        >
            <div>Response Variable</div>
            <SelectNeoGen
                className="mb-5"
                label="Processflow Group"
                options={
                    processflowGroupQuery.data?.map((p) => ({
                        name: p.name,
                        id: p.id,
                    })) ?? []
                }
                value={processflowGroup}
                onChange={(idx) => setProcessflowGroup(Number(idx))}
            />
            <SelectNeoGen
                className="mb-5"
                label="Processflow"
                options={
                    processflowQuery.data?.map((p) => ({
                        name: p.title,
                        id: p.id,
                    })) ?? []
                }
                value={processflow}
                onChange={(idx) => setProcessflow(Number(idx))}
            />
            <SelectNeoGen
                className="mb-5"
                label="Field"
                options={
                    entries.map((p) => ({
                        name: p.value,
                        id: p.id,
                    })) ?? []
                }
                value={entry}
                onChange={(idx) => setEntry(String(idx))}
            />
            {/* {processflowGroup} */}
            {/* <PrintPre>{entries}</PrintPre> */}
        </ModalDialog>
    );
}
