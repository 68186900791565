import { useMutation } from "@tanstack/react-query";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import { useAuth } from "../../auth/use-auth";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import { useEffect, useMemo, useRef, useState } from "react";
import { groupBy, orderBy, startCase } from "lodash";
import ButtonNeoGen from "../../layout/button-neogen";
import { AutoAssignMandatesModal } from "../../company-mandate-assignments/components/auto-assign-mandates-modal";
import {
    UpdateCompanyMandateAssignment,
    updateCompanyMandateAssignment,
} from "../../company-mandate-assignments/actions/update-company-mandate-assignment";
import { AddMandateAssignmentModal } from "../../company-mandate-assignments/components/add-mandate-assignment-modal";
import { useMandates } from "../../mandates/hooks/use-mandates";
import { useMandateMandateEffects } from "../../mandate-mandate-effects/hooks/use-mandate-mandate-effects";
import { useIndustryGroups } from "../../industry-groups/hooks/use-industry-groups";
import { useMandateEffects } from "../../mandate-effects/hooks/use-mandate-effects";
import { useCompanyMandateAssignments } from "../../company-mandate-assignments/hooks/use-company-mandate-assignments";
import { MandateEffectModal } from "../../mandate-effects/components/mandate-effect-modal";
import { ViewportList } from "react-viewport-list";
import React from "react";
import { MandateEffectRejectionModal } from "../../mandates/components/mandate-effect-rejection-modal";
import { MandateRow } from "./mandate-row";
import { MandateFilters, MandateFiltersModal } from "../../mandates/components/mandate-filters-modal";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import SelectNeoGen from "../../layout/select-neogen";
import { classNames } from "../../billing/utils";
import exp from "constants";
import { format } from "date-fns";

export const Mandates = ({ companyId, owner }: { companyId: number; owner: User }) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const [showAutoAssignModal, setShowAutoAssignModal] = useState(false);
    const [showAddMandateEffectModal, setShowAddMandateEffectModal] = useState(false);
    const [mandateMandateEffectId, setMandateEffectId] = useState<number | undefined>();
    const ref = useRef<HTMLDivElement | null>(null);
    const [showRejectionModalForAssignment, setShowRejectionModalForAssignment] = useState<number | undefined>();
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState<MandateFilters>({});
    const [expanded, setExpanded] = useState(JSON.parse(localStorage.getItem("expanded") || "{}"));
    const [sortedBy, setSortedBy] = useState<string>("name");
    const [groupedBy, setGroupedBy] = useState<string>("none");
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
    const [activeTab, setActiveTab] = useState("Accepted");

    useEffect(() => {
        localStorage.setItem("expanded", JSON.stringify(expanded));
    }, [expanded]);

    const companyMandateAssignmentsQuery = useCompanyMandateAssignments({
        authToken,
        filters: { where: { companyId } },
    });
    const companyMandateAssignments = useMemo(
        () => companyMandateAssignmentsQuery.data || [],
        [companyMandateAssignmentsQuery.data],
    );

    const mandateIds = companyMandateAssignments.map((assignment) => assignment.mandateId);
    const mandatesQuery = useMandates(
        { authToken, filters: { where: { id: { inq: mandateIds } } } },
        {
            enabled: companyMandateAssignmentsQuery.isSuccess && companyMandateAssignments.length > 0,
        },
    );
    const mandates = useMemo(() => mandatesQuery.data || [], [mandatesQuery.data]);

    const mandateEffectIds = companyMandateAssignments.map((a) => a.mandateEffectId);
    const mandateEffectsQuery = useMandateEffects(
        {
            authToken,
            filters: { where: { id: { inq: mandateEffectIds } } },
        },
        {
            enabled: companyMandateAssignmentsQuery.isSuccess && companyMandateAssignments.length > 0,
        },
    );
    const mandateEffects = useMemo(() => mandateEffectsQuery.data || [], [mandateEffectsQuery.data]);

    const mandateMandateEffectsQuery = useMandateMandateEffects(
        {
            authToken,
            filters: { where: { mandateId: { inq: mandateIds, mandateEffectId: { inq: mandateEffectIds } } } },
        },
        {
            enabled: companyMandateAssignmentsQuery.isSuccess && companyMandateAssignments.length > 0,
        },
    );
    const mandateMandateEffects = useMemo(
        () => mandateMandateEffectsQuery.data || [],
        [mandateMandateEffectsQuery.data],
    );

    const i = companyMandateAssignments.filter((a) => !!a.industryGroupId);

    const industryGroupIds = i.map((assignment) => assignment.industryGroupId);
    const industryGroupsQuery = useIndustryGroups(
        {
            authToken,
            filters: { where: { id: { inq: industryGroupIds } } },
        },
        {
            enabled: companyMandateAssignmentsQuery.isSuccess && i.length > 0,
        },
    );
    const industryGroups = useMemo(() => industryGroupsQuery.data || [], [industryGroupsQuery.data]);

    const rows = useMemo(
        () =>
            orderBy(
                companyMandateAssignments
                    .map((cma) => {
                        return {
                            companyMandateAssignment: cma,
                            mandate: mandates.find((m) => m.id === cma.mandateId),
                            mandateEffect: mandateEffects.find((me) => me.id === cma.mandateEffectId),
                            mandateMandateEffect: mandateMandateEffects.find(
                                (me) => me.mandateId === cma.mandateId && me.mandateEffectId === cma.mandateEffectId,
                            ),
                            industryGroup: industryGroups.find((ig) => ig.id === cma.industryGroupId),
                        };
                    })
                    .filter((row) => {
                        const mandateIds = filters.mandateIds || [];
                        const mandateIdCheck =
                            mandateIds.length > 0 ? row.mandate && mandateIds.includes(row.mandate.id) : true;

                        const statuses = filters.statuses || [];
                        const statusCheck =
                            statuses.length > 0
                                ? row.companyMandateAssignment.status &&
                                  statuses.includes(row.companyMandateAssignment.status)
                                : true;

                        const effectIds = filters.effectIds || [];
                        const effectCheck =
                            effectIds.length > 0
                                ? row.mandateEffect?.id && effectIds.includes(row.mandateEffect.id)
                                : true;

                        return mandateIdCheck && statusCheck && effectCheck;
                    }),
                (row) => {
                    if (sortedBy === "name") {
                        return row.mandate?.name?.toLocaleLowerCase();
                    }
                    if (sortedBy === "date") {
                        return row.mandate?.date;
                    }
                    if (sortedBy === "state") {
                        return row.mandate?.state?.toLocaleLowerCase();
                    }
                    if (sortedBy === "effect") {
                        return row.mandateEffect?.effectName.toLowerCase();
                    }
                    return row.mandate?.name;
                },
                sortDirection,
            ),
        [
            companyMandateAssignments,
            filters.effectIds,
            filters.mandateIds,
            filters.statuses,
            industryGroups,
            mandateEffects,
            mandateMandateEffects,
            mandates,
            sortedBy,
            sortDirection,
        ],
    );

    const updateCompanyMandateAssignmentMutation = useMutation({
        mutationKey: ["updateCompanyMandateAssignment", { authToken }],
        mutationFn: async ({ id, data }: { id: number; data: UpdateCompanyMandateAssignment }) => {
            const updatedMandateAssignment = await updateCompanyMandateAssignment({ authToken, id, data });
            return updatedMandateAssignment;
        },
        onSettled: () => companyMandateAssignmentsQuery.refetch(),
    });

    const mandateMandateEffectToView = useMemo(
        () => mandateMandateEffects.find((mme) => mme.id === mandateMandateEffectId),
        [mandateMandateEffects, mandateMandateEffectId],
    );

    const appliedFilters = Object.keys(filters).filter((k) => !!(filters as any)?.[k]).length;

    const acceptedRows = rows.filter((row) => row.companyMandateAssignment?.status === "accepted");
    const rejectedRows = rows.filter((row) => row.companyMandateAssignment?.status === "rejected");
    const suggestedRows = rows.filter((row) => row.companyMandateAssignment?.status === "suggested");

    const tabs = [
        { name: "Accepted", count: acceptedRows.length },
        { name: "Rejected", count: rejectedRows.length },
        { name: "Suggested", count: suggestedRows.length },
        { name: "All", count: rows.length },
    ];

    const rowsToShow =
        activeTab === "Accepted"
            ? acceptedRows
            : activeTab === "Rejected"
            ? rejectedRows
            : activeTab === "Suggested"
            ? suggestedRows
            : activeTab === "All"
            ? rows
            : [];

    const groupedRows =
        groupedBy !== "none"
            ? groupBy(rowsToShow, (row) => {
                  if (groupedBy === "date") {
                      return format(row.mandate?.date || new Date(), "yyyy-MM-dd");
                  }
                  if (groupedBy === "name") {
                      return row.mandate?.name;
                  }
                  if (groupedBy === "effect") {
                      return row.mandateEffect?.effectName;
                  }
              })
            : [];

    return (
        <>
            {showAutoAssignModal && (
                <AutoAssignMandatesModal
                    companyId={companyId}
                    onClose={() => setShowAutoAssignModal(false)}
                    onAssigned={() => {
                        companyMandateAssignmentsQuery.refetch();
                        setShowAutoAssignModal(false);
                    }}
                />
            )}
            {showAddMandateEffectModal && (
                <AddMandateAssignmentModal
                    companyId={companyId}
                    onClose={() => setShowAddMandateEffectModal(false)}
                    onCreated={() => {
                        setShowAddMandateEffectModal(false);
                        companyMandateAssignmentsQuery.refetch();
                    }}
                />
            )}
            {mandateMandateEffectToView && (
                <MandateEffectModal
                    mandateMandateEffect={mandateMandateEffectToView}
                    onClose={() => setMandateEffectId(undefined)}
                />
            )}
            {showRejectionModalForAssignment && (
                <MandateEffectRejectionModal
                    onClose={() => {
                        setShowRejectionModalForAssignment(undefined);
                    }}
                    onReject={(data) => {
                        updateCompanyMandateAssignmentMutation.mutate({
                            id: showRejectionModalForAssignment,
                            data: { status: "rejected", rejectionReason: data.rejectionReason },
                        });
                        setShowRejectionModalForAssignment(undefined);
                    }}
                />
            )}
            {showFilters && mandates && (
                <MandateFiltersModal
                    onClose={() => setShowFilters(false)}
                    onSave={(filters) => {
                        setFilters(filters);
                        setShowFilters(false);
                    }}
                    mandates={mandates}
                    mandateEffects={mandateEffects}
                    mandateFilters={filters}
                />
            )}
            <div style={{ padding: 12 }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Mandate Effects</div>
                    <div className="flex flex-row gap-1">
                        <div className="flex flex-row items-center gap-1">
                            <div>Group By</div>
                            <div>
                                <SelectNeoGen
                                    value={groupedBy}
                                    options={[
                                        { id: "none", name: "None" },
                                        { id: "name", name: "Name" },
                                        { id: "date", name: "Date" },
                                        { id: "effect", name: "Effect" },
                                    ]}
                                    onChange={(val) => {
                                        return setGroupedBy(val as string);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row items-center gap-1">
                            <div>Sort By</div>
                            <div style={{ minWidth: 100 }}>
                                <SelectNeoGen
                                    value={sortedBy}
                                    options={[
                                        { id: "name", name: "Name" },
                                        { id: "date", name: "Date" },
                                        { id: "state", name: "State" },
                                        { id: "effect", name: "Effect" },
                                    ]}
                                    onChange={(val) => {
                                        return setSortedBy(val as string);
                                    }}
                                />
                            </div>
                            <div style={{ minWidth: 80 }}>
                                <SelectNeoGen
                                    value={sortDirection}
                                    options={[
                                        { id: "asc", name: "Asc" },
                                        { id: "desc", name: "Desc" },
                                    ]}
                                    onChange={(val) => {
                                        return setSortDirection(val as any);
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <ButtonNeoGen onClick={() => setShowFilters(true)}>
                                Filters {appliedFilters > 0 ? `(${appliedFilters})` : ""}
                            </ButtonNeoGen>
                        </div>
                        <div>
                            <ButtonNeoGen onClick={() => setShowAddMandateEffectModal(true)}>
                                Add Mandate Effect
                            </ButtonNeoGen>
                        </div>
                        <div>
                            <ButtonNeoGen
                                onClick={() => {
                                    setShowAutoAssignModal(true);
                                }}
                            >
                                Auto-Assign Mandates
                            </ButtonNeoGen>
                        </div>
                    </div>
                </div>
                <div className="border-b border-gray-200">
                    <nav className="flex space-x-8">
                        {tabs.map((tab) => (
                            <span
                                key={tab.name}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setActiveTab(tab.name);
                                }}
                                className={classNames(
                                    tab.name === activeTab
                                        ? "border-black text-black"
                                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                    "whitespace-nowrap py-3 px-1 border-b-2 font-medium text-sm",
                                )}
                            >
                                {tab.name} ({tab.count})
                            </span>
                        ))}
                    </nav>
                </div>
                <div ref={ref} style={{ height: "calc(100vh - 260px)", overflow: "auto" }}>
                    <Table>
                        <THead>
                            <Tr>
                                <Th style={{ width: 190 }}>Mandate</Th>
                                <Th>Effect</Th>
                                <Th>{activeTab === "Rejected" ? "Rejection Reason" : "Impact"}</Th>
                                <Th style={{ width: 190 }}>Status</Th>
                            </Tr>
                        </THead>
                        <TBody>
                            {groupedBy !== "none" && (
                                <>
                                    {Object.keys(groupedRows).map((key) => {
                                        const rowsToShow = (groupedRows as any)?.[key] || [];
                                        return (
                                            <>
                                                {rowsToShow.length === 0 && (
                                                    <Tr>
                                                        <Td colSpan={5} style={{ textAlign: "center" }}>
                                                            No Mandate Effects
                                                        </Td>
                                                    </Tr>
                                                )}
                                                {rowsToShow.length > 0 && (
                                                    <React.Fragment key={key}>
                                                        <Tr>
                                                            <Td
                                                                colSpan={4}
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setExpanded({
                                                                        ...expanded,
                                                                        [key]:
                                                                            expanded[key] === undefined
                                                                                ? false
                                                                                : !expanded[key],
                                                                    });
                                                                }}
                                                            >
                                                                <div className="flex flex-row gap-1">
                                                                    <div style={{ width: 20 }}>
                                                                        {expanded[key] === false ? (
                                                                            <ChevronRightIcon />
                                                                        ) : (
                                                                            <ChevronDownIcon />
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <b>
                                                                            {key} ({rowsToShow.length})
                                                                        </b>
                                                                    </div>
                                                                </div>
                                                            </Td>
                                                        </Tr>
                                                        {expanded[key] !== false && (
                                                            <ViewportList
                                                                viewportRef={ref}
                                                                items={rowsToShow}
                                                                renderSpacer={({ ref, style }) => (
                                                                    <Tr ref={ref} style={style} />
                                                                )}
                                                                initialPrerender={25}
                                                                overscan={10}
                                                            >
                                                                {(row: any) => {
                                                                    return (
                                                                        <MandateRow
                                                                            key={row.companyMandateAssignment.id}
                                                                            companyMandateAssignment={
                                                                                row.companyMandateAssignment
                                                                            }
                                                                            industryGroup={row.industryGroup}
                                                                            mandate={row.mandate}
                                                                            mandateEffect={row.mandateEffect}
                                                                            mandateMandateEffect={
                                                                                row.mandateMandateEffect
                                                                            }
                                                                            onMandateMandateEffectClick={(id) =>
                                                                                setMandateEffectId(id)
                                                                            }
                                                                            onRejectionReasonChange={(val) => {
                                                                                updateCompanyMandateAssignmentMutation.mutate(
                                                                                    {
                                                                                        id: row.companyMandateAssignment
                                                                                            .id,
                                                                                        data: { rejectionReason: val },
                                                                                    },
                                                                                );
                                                                            }}
                                                                            onImpactChange={(val) => {
                                                                                updateCompanyMandateAssignmentMutation.mutate(
                                                                                    {
                                                                                        id: row.companyMandateAssignment
                                                                                            .id,
                                                                                        data: { impact: val },
                                                                                    },
                                                                                );
                                                                            }}
                                                                            onStatusChange={(val: any) => {
                                                                                if (val === "rejected") {
                                                                                    setShowRejectionModalForAssignment(
                                                                                        row.companyMandateAssignment.id,
                                                                                    );
                                                                                } else if (val) {
                                                                                    updateCompanyMandateAssignmentMutation.mutate(
                                                                                        {
                                                                                            id: row
                                                                                                .companyMandateAssignment
                                                                                                .id,
                                                                                            data: {
                                                                                                status: val,
                                                                                                rejectionReason: null,
                                                                                            },
                                                                                        },
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                    );
                                                                }}
                                                            </ViewportList>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </>
                                        );
                                    })}
                                </>
                            )}
                            {groupedBy === "none" && (
                                <>
                                    {rowsToShow.length === 0 && (
                                        <Tr>
                                            <Td colSpan={4} style={{ textAlign: "center" }}>
                                                No Mandate Effects
                                            </Td>
                                        </Tr>
                                    )}
                                    {rowsToShow.length > 0 && (
                                        <ViewportList
                                            viewportRef={ref}
                                            items={rowsToShow}
                                            renderSpacer={({ ref, style }) => <Tr ref={ref} style={style} />}
                                            initialPrerender={25}
                                            overscan={10}
                                        >
                                            {(row: any) => {
                                                return (
                                                    <MandateRow
                                                        key={row.companyMandateAssignment.id}
                                                        companyMandateAssignment={row.companyMandateAssignment}
                                                        industryGroup={row.industryGroup}
                                                        mandate={row.mandate}
                                                        mandateEffect={row.mandateEffect}
                                                        mandateMandateEffect={row.mandateMandateEffect}
                                                        onMandateMandateEffectClick={(id) => setMandateEffectId(id)}
                                                        onRejectionReasonChange={(val) => {
                                                            updateCompanyMandateAssignmentMutation.mutate({
                                                                id: row.companyMandateAssignment.id,
                                                                data: { rejectionReason: val },
                                                            });
                                                        }}
                                                        onImpactChange={(val) => {
                                                            updateCompanyMandateAssignmentMutation.mutate({
                                                                id: row.companyMandateAssignment.id,
                                                                data: { impact: val },
                                                            });
                                                        }}
                                                        onStatusChange={(val: any) => {
                                                            if (val === "rejected") {
                                                                setShowRejectionModalForAssignment(
                                                                    row.companyMandateAssignment.id,
                                                                );
                                                            } else if (val) {
                                                                updateCompanyMandateAssignmentMutation.mutate({
                                                                    id: row.companyMandateAssignment.id,
                                                                    data: {
                                                                        status: val,
                                                                        rejectionReason: null,
                                                                    },
                                                                });
                                                            }
                                                        }}
                                                    />
                                                );
                                            }}
                                        </ViewportList>
                                    )}
                                </>
                            )}
                        </TBody>
                    </Table>
                </div>
            </div>
        </>
    );
};
