import ModalDialog from "../../layout/modal-dialog";
import { MandateMandateEffect } from "../../mandates/domain/mandate-mandate-effect";
import { MandateEffect } from "../domain/mandate-effect";

export const MandateEffectModal = ({
    onClose,
    mandateMandateEffect,
}: {
    onClose: () => void;
    mandateMandateEffect: MandateMandateEffect;
}) => {
    return (
        <ModalDialog title="Mandate Effect" show close={onClose}>
            {mandateMandateEffect.description}
        </ModalDialog>
    );
};
