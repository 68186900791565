import { useQuery } from "@tanstack/react-query";
import { getCompanyMandateAssignments } from "../actions/get-company-mandate-assignments";

type Params = {
    authToken: string;
    filters?: any;
};

const getCompanyMandateAssignmentsQueryKey = ({ authToken, filters }: Params) => {
    return ["companyMandateAssignments", { authToken, filters }];
};

export const useCompanyMandateAssignments = ({ authToken, filters }: Params, options?: { enabled?: boolean }) => {
    const query = useQuery({
        queryKey: getCompanyMandateAssignmentsQueryKey({ authToken, filters }),
        queryFn: async () => {
            const companyMandateAssignments = await getCompanyMandateAssignments({ authToken, filters });
            return companyMandateAssignments;
        },
        keepPreviousData: true,
        ...options,
    });
    return query;
};
