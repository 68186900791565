import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { mandateSchema } from "../domain/mandate";

export const getMandates = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/mandates",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const mandates = parseSchema(z.array(mandateSchema), response.data?.pageItems || []);

    return mandates;
};
