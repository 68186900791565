import { useState } from "react";
import { CompanyLocation } from "../../../company-locations/domain/company-location";
import ButtonNeoGen from "../../../layout/button-neogen";
import ModalDialog from "../../../layout/modal-dialog";
import { TBody, THead, Table, Td, Th, Tr } from "../../../layout/table";
import { NewCompanyLocationModal } from "./new-company-location-modal";
import Swal from "sweetalert2";
import { useMutation } from "@tanstack/react-query";
import { deleteCompanyLocation } from "../../../company-locations/actions/delete-company-location";
import { useAuth } from "../../../auth/use-auth";
import { EditCompanyLocationModal } from "./edit-company-location-modal";

export const CompanyLocationsModal = ({
    onClose,
    companyLocations,
    onCreate,
    companyId,
    onDelete,
    onUpdate,
}: {
    onClose: () => void;
    companyLocations: CompanyLocation[];
    onCreate: (companyLocation: CompanyLocation) => void;
    companyId: number;
    onDelete: (companyLocationId: number) => void;
    onUpdate: (companyLocation: CompanyLocation) => void;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const [showNewLocationModal, setShowNewLocationModal] = useState(false);
    const [showEditLocationModalId, setShowEditLocationModalId] = useState<number | undefined>();

    const deleteMutation = useMutation({
        mutationFn: async (id: number) => {
            await deleteCompanyLocation({ authToken, id });
            onDelete(id);
        },
    });

    const companyLocationToEdit = companyLocations.find((location) => location.id === showEditLocationModalId);

    return (
        <>
            {showNewLocationModal && (
                <NewCompanyLocationModal
                    onClose={() => setShowNewLocationModal(false)}
                    onCreate={(location) => {
                        onCreate(location);
                        setShowNewLocationModal(false);
                    }}
                    companyId={companyId}
                />
            )}
            {companyLocationToEdit && (
                <EditCompanyLocationModal
                    companyId={companyId}
                    onClose={() => setShowEditLocationModalId(undefined)}
                    companyLocation={companyLocationToEdit}
                    onUpdate={(location) => {
                        onUpdate(location);
                        setShowEditLocationModalId(undefined);
                    }}
                />
            )}
            <ModalDialog title="Locations" show close={onClose} size="md" okAction={onClose}>
                <div className="flex justify-end">
                    <ButtonNeoGen onClick={() => setShowNewLocationModal(true)}>New Location</ButtonNeoGen>
                </div>
                <Table>
                    <THead>
                        <Tr>
                            <Th>Location</Th>
                            <Th>Percent of Business</Th>
                            <Th>Notes</Th>
                            <Th style={{ textAlign: "right" }}></Th>
                        </Tr>
                    </THead>
                    <TBody>
                        {companyLocations.length === 0 && (
                            <Tr>
                                <Td colSpan={3} style={{ textAlign: "center" }}>
                                    No Additional Locations
                                </Td>
                            </Tr>
                        )}
                        {companyLocations.length > 0 &&
                            companyLocations.map((location) => {
                                return (
                                    <Tr key={location.id}>
                                        <Td>{[location.city, location.state].join(", ")}</Td>
                                        <Td>{location.percentOfBusiness}%</Td>
                                        <Td>{location.notes}</Td>
                                        <Td style={{ textAlign: "right" }}>
                                            <div>
                                                <ButtonNeoGen
                                                    size="sm"
                                                    type="outline"
                                                    onClick={() => setShowEditLocationModalId(location.id)}
                                                >
                                                    Edit
                                                </ButtonNeoGen>
                                                <ButtonNeoGen
                                                    size="sm"
                                                    type="outline-danger"
                                                    onClick={() => {
                                                        Swal.fire({
                                                            text: "Are you sure you want to delete this location?",
                                                            icon: "warning",
                                                            showCancelButton: true,
                                                            confirmButtonColor: "#d33",
                                                            confirmButtonText: "Yes, delete it",
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                deleteMutation.mutate(location.id);
                                                            }
                                                        });
                                                    }}
                                                >
                                                    Remove
                                                </ButtonNeoGen>
                                            </div>
                                        </Td>
                                    </Tr>
                                );
                            })}
                    </TBody>
                </Table>
            </ModalDialog>
        </>
    );
};
