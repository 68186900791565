import { useQuery } from "@tanstack/react-query";
import helpDocsService from "../../services/help-docs.service";
import Loader2 from "../utilities/Loader2";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useState } from "react";
import { NeoGenHelpDocs } from "../../typings/api";
import DeleteFaq from "./modals/delete-faq";
import { useNavigate } from "react-router-dom";
import PageDescription from "../../layout/page-description";
import TableNeogen from "../../layout/table-neogen";
import packageSectionRulesService from "../../services/package-section-rules.service";

export default function Faq() {
    const [showDeleteFaq, setShowDeleteFaq] = useState(false);
    const [existing, setExisting] = useState<null | NeoGenHelpDocs>(null);
    const navigate = useNavigate();
    const helpDocsQuery = useQuery(["HelpDocs", "sorted"], async () => {
        const response = await helpDocsService.getAll();
        if (response) {
            return response.data.sort((a, b) => {
                if ((a.order ?? 0) > (b.order ?? 0)) return 1;
                if ((a.order ?? 0) < (b.order ?? 0)) return -1;

                return 0;
            });
        }
    });
    const rulesQuery = useQuery(["packageSectionRules"], async () => {
        const response = await packageSectionRulesService.getAll();
        return response?.data ?? [];
    });

    return (
        <div>
            <div>
                {helpDocsQuery.isLoading ? (
                    <Loader2 />
                ) : (
                    <>
                        <PageDescription
                            title="Sections"
                            description="Package Sections"
                            buttons={[
                                {
                                    label: "Add Section",
                                    onClick: () => {
                                        navigate("/faq/new");
                                    },
                                },
                            ]}
                        />
                        <TableNeogen
                            entries={
                                helpDocsQuery.data?.map((e: any, idx: number) => {
                                    return {
                                        id: e.id,
                                        title: e.title,
                                        tags: e.tags,
                                        updated: e.updated,
                                        created: e.created,
                                        order: idx,
                                    };
                                }) ?? []
                            }
                            formatters={[
                                { field: "updated", type: "DateTime" },
                                { field: "created", type: "DateTime" },
                            ]}
                            actions={[
                                {
                                    label: "Edit",
                                    icon: "fas fa-edit",
                                    onClick: (e) => {
                                        const entry = helpDocsQuery.data?.find((h) => h.id === e);
                                        if (entry) {
                                            setExisting(entry);
                                            navigate("/faq/edit/" + entry.id);
                                        }
                                    },
                                },
                                {
                                    labelActive: (id) => {
                                        return (
                                            "Rules (" + rulesQuery.data?.filter((i) => i.helpDoc === id).length + ")"
                                        );
                                    },
                                    label: "",
                                    icon: "fas fa-gavel",
                                    type: "info",
                                    onClick: (e) => {
                                        const entry = helpDocsQuery.data?.find((h) => h.id === e);
                                        if (entry) {
                                            navigate("/package-section-rules/" + entry.id);
                                        }
                                    },
                                },
                                {
                                    label: "",
                                    icon: "fas fa-caret-up",
                                    onClick: (e) => {
                                        const entry = helpDocsQuery.data?.find((h) => h.id === e);
                                        if (entry) {
                                            // Go through the helpdocs and find the one with the index of e
                                            for (let i = 0; i < (helpDocsQuery.data?.length ?? 0); i++) {
                                                if (helpDocsQuery.data?.[i]?.id === e) {
                                                    const temp = helpDocsQuery?.data?.[i - 1];
                                                    if (temp) {
                                                        temp.order = i;
                                                        helpDocsService.update(temp.id, temp);
                                                        entry.order = i - 1;
                                                        helpDocsService.update(entry.id, entry);
                                                        helpDocsQuery.refetch();
                                                    }
                                                }
                                            }
                                            helpDocsQuery.refetch();
                                        }
                                    },
                                },
                                {
                                    label: "",
                                    icon: "fas fa-caret-down",
                                    onClick: async (e) => {
                                        const entry = helpDocsQuery.data?.find((h) => h.id === e);
                                        if (entry) {
                                            for (let i = 0; i < (helpDocsQuery.data?.length ?? 0); i++) {
                                                if (helpDocsQuery.data?.[i]?.id === e) {
                                                    const temp = helpDocsQuery?.data?.[i + 1];
                                                    if (temp) {
                                                        entry.order = i + 1;
                                                        helpDocsService.update(entry.id, entry);
                                                        temp.order = i;
                                                        helpDocsService.update(temp.id, temp);

                                                        await helpDocsQuery.refetch();

                                                        break;
                                                    }
                                                }
                                            }
                                            helpDocsQuery.refetch();
                                        }
                                    },
                                },
                                {
                                    label: "Delete",
                                    icon: "fas fa-trash",
                                    type: "danger",
                                    onClick: (e) => {
                                        const entry = helpDocsQuery.data?.find((h) => h.id === e);
                                        if (entry) {
                                            setExisting(entry);

                                            setShowDeleteFaq(true);
                                        }
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <DeleteFaq show={showDeleteFaq} hide={() => setShowDeleteFaq(false)} existingEntry={existing} />
        </div>
    );
}
