import { z } from "zod";
import { makeRequest, parseSchema } from "../../shared/utils";
import { processflowSchema } from "../domain/processflow";

const processflowResponseSchema = z.object({
    id: z.number(),
    title: z.string(),
    causesRepContactOnCompletion: z.number(),
    checkboxEnabler: z.string(),
    checkboxEntries: z.any(),
    datePickerEntries: z.string().nullable(),
    details: z.string(),
    entries: z.string().nullable(),
    fileUploadEntries: z.string().nullable(),
    group: z.number(),
    helpDoc: z.number(),
    inputType: z.number(),
    internalComments: z.string(),
    internalOnly: z.number(),
    optional: z.number(),
    order: z.number().nullable(),
    reliesOnFalse: z.number(),
    reliesOnTrue: z.number(),
    selectBoxEntries: z.any(),
    sendsEmailOnCompletion: z.number(),
    stage: z.number(),
    stateEntries: z.string().nullable(),
    textareaEntries: z.any(),
    textfieldEntries: z.any(),
    textfieldName: z.string(),
    yesNoEntries: z.any(),
    ruleGroupsAreOr: z.number().nullable(),
    businessRuleGroups: z.any(),
});

const getProcessflowsResponseSchema = z.array(processflowResponseSchema);

export const getProcessflows = async ({ authToken, group }: { authToken?: string; group: number }) => {
    const response = await makeRequest({
        method: "get",
        path: "/processflows",
        authToken,
        queryParams: {
            filter: JSON.stringify({
                where: { group },
                include: [
                    {
                        relation: "businessRuleGroups",
                        scope: {
                            include: [
                                {
                                    relation: "businessRules",
                                },
                            ],
                        },
                    },
                ],
                order: ["order ASC", "id ASC"],
            }),
        },
    });
    const processFlowsDto = parseSchema(getProcessflowsResponseSchema, response.data);
    try {
        const processFlows = parseSchema(
            z.array(processflowSchema),
            processFlowsDto
                .map((dto) => ({
                    ...dto,
                    causesRepContactOnCompletion: dto.causesRepContactOnCompletion === 0 ? false : true,
                    checkboxEntries: dto.checkboxEntries ? dto.checkboxEntries : null,
                    datePickerEntries: dto.datePickerEntries ? dto.datePickerEntries : null,
                    entries: dto.entries ? JSON.parse(dto.entries) : null,
                    fileUploadEntries: dto.fileUploadEntries ? dto.fileUploadEntries : null,
                    textareaEntries: dto.textareaEntries ? dto.textareaEntries : null,
                    textfieldEntries: dto.textfieldEntries ? dto.textfieldEntries : null,
                    selectBoxEntries: dto.selectBoxEntries ? dto.selectBoxEntries : null,
                    ruleGroupsAreOr: dto.ruleGroupsAreOr === 1,
                }))
                .map((dto) => ({
                    ...dto,
                    entries: ((dto.entries as any[]) || []).map((entry) => ({
                        ...entry,
                        widthOf12Cols: entry.widthOf12Cols ? Number.parseInt(entry.widthOf12Cols) : undefined,
                        widthOf300Cols: entry.widthOf300Cols ? Number.parseInt(entry.widthOf300Cols) : undefined,
                    })),
                })),
        );
        return processFlows;
    } catch (error) {
        console.error("Error parsing processflows", error);
        console.error({ processFlowsDto, data: response.data });
        throw error;
    }
};
