import { useQuery } from "@tanstack/react-query";
import { getMandates } from "../actions/get-mandates";

type Params = {
    authToken: string;
    filters?: any;
};

const getMandatesQueryKey = ({ authToken, filters }: Params) => {
    return ["mandates", { authToken, filters }];
};

export const useMandates = ({ authToken, filters }: Params, options?: { enabled?: boolean }) => {
    const query = useQuery({
        queryKey: getMandatesQueryKey({ authToken, filters }),
        queryFn: async () => {
            const mandates = await getMandates({ authToken, filters });
            return mandates;
        },
        keepPreviousData: true,
        ...options,
    });
    return query;
};
